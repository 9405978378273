@import '../../../../../styles/customMediaQueries.css';

.customLinksMenu {
  flex-grow: 1;
  display: flex;
  justify-content: right;
  height: 100%;

  /* TODO: */
  min-width: 200px;
}
.createListingLinkOnly {
  display: flex;
  justify-content: right;
  height: 100%;
}

.priorityLink {
  border-bottom: 0px solid;
  /* transition: var(--transitionStyleButton); */

  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0;
  color: #000000;

  flex-shrink: 0;
  height: 37px;
  padding: 8px 32px;
  margin: 22px 0;

  &:hover {
    color: #fdfdfd;
    border-radius: 20px;
    padding: 8px 32px;
    background-color: #9191d6;
    text-decoration: none;
  }
}
